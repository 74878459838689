


//console.log(progressarray)

export default {


  lineAreaChartSpline: {

    
   // series: [
   //   {
   //     name: 'Scan Progress',
   //     data: progressort,
  //
  //      },
  //    ],
    chartOptions: {
      
      chart: {
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: false,
        curve: 'smooth',
      },
      legend: {
        show: false,
        position: 'top',
        horizontalAlign: 'left',
        fontSize: '14px',
        fontFamily: 'Montserrat',
      },
      grid: {
        show: true,
        
        xaxis: {
          lines: {
            show: false,
          },
   
        
   
        },

      },
      xaxis: {
        
        show: false,
        labels: {
          show: false
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
      },
      yaxis: {
        // opposite: isRtl
        show: false,
        labels: {
          show: false
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      fill: {
        colors:['#ee3b3b', '#ee3b3b11'],

        type: 'gradient',
        gradient: {
          type: "horizontal",
          shadeIntensity: 0.1,
          gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
          inverseColors: true,
          opacityFrom: 0.6,
          opacityTo: 0.9,
          
          stops: [30,95],
          colorStops: []
        }

      },
      tooltip: {
        shared: false,
      },
    },
  },


}
