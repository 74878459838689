<template>

    <div>

 
        <b-card>

            <vue-apex-charts
        ref="junoseveritychartabcd"
        type="area"
        height="400"
        :options="scanProgessChartData.lineAreaChartSpline.chartOptions"
        :series="junochartdatabcde"
      />      </b-card>

     


    
  </div>


</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle,BRow, BCol 
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import scanProgessChartData from './scanProgessChartData.js'

//random number generator
import uniqueRandom from 'unique-random';
 //dompurify prevent xss
 import * as DOMPurify from 'dompurify'




export default {
  components: {
    BRow,
    BCol,
    BCard,
    VueApexCharts,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    
},
  data() {
    return {
        scanProgessChartData,
        junochartdatabcde: [{ data:[0,0,0,0] }],

    }
  },
  mounted(){
    



    const userapikey =  localStorage.getItem('userapikey')
    const scandomain = localStorage.getItem('scandomain')
    const scanid = localStorage.getItem('scanid')
  
    let userapikeysanitized = DOMPurify.sanitize(userapikey);
    let scandomainsanitized = DOMPurify.sanitize(scandomain);
    let scanidsanitized = DOMPurify.sanitize(scanid);
  
    setTimeout(()=> {
          //timeout for lazy loading and to load after fetching data only
  
  
      this.$http.get(`${process.env.VUE_APP_SECURITYINFINITYFRONTEND_URL}`+'/api/report_dashboard/fetchreport?reporttype=scanstatus&userapikey='+userapikeysanitized+'&scandomain='+scandomainsanitized+'&scanid='+scanidsanitized)
      .then((response) =>  {
  
        const scanstatusoutput = response.data;
        global.scanprogress =  Math.round(scanstatusoutput[0].scanprogress);





        if (global.scanprogress == null)
          global.scanprogresscount = 0
        else 
          global.scanprogresscount = global.scanprogress
  
      
    //scan progress randomize chart juno
    //console.log(scanprogresscount)
    const random = uniqueRandom(1, scanprogresscount);
    const progressarray = [random(),random(),random(),random(),random(),random(),scanprogresscount]
    const progressort = progressarray.sort((a, b) =>  a - b)

    global.output = progressort

    global.outputformat =  [{data:global.output }]

    //console.log(global.outputformat)
  
    //sets the chart values on chart with id  junoseveritychart
    //values will be only accessible when variables are declared as global 
       
    this.$refs.junoseveritychartabcd.updateSeries	(global.outputformat , true )
        
    //the lines below works tested by juno
    //global.outputformats =  [{ data:[5,5,5,5] }]
    //this.$refs.junoseveritychartabcd.updateSeries	(global.outputformats , true )
  
      })
    
    }, 2000)
  
  }  
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';

//hides gridelines by the chart --juno fix
.apexcharts-gridline {
    stroke-width: 0px;
}
</style>
