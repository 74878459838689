<template>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Cyber Risk Score</b-card-title>
  
      </b-card-header>
      <!-- title and dropdown -->
  
      <!--/ title and dropdown -->
  
      <b-card-body>
        <b-row>
  
  
          <!-- chart -->
          <b-col
            class="d-flex justify-content-center"
          >
  
          
            <!-- apex chart -->
            <vue-apex-charts
              ref="junoseveritychartrisk"
              type="radialBar"
              height="400"
              :options="supportTrackerRadialBar.chartOptions"
              :series="junochartdatarisk"
            />
          </b-col>
          <!--/ chart -->
        </b-row>
  
      </b-card-body>
    </b-card>
  </template>
  
  <script>
  import {
    BCard, BCardHeader, BCardTitle, BDropdown, BDropdownItem, BCardBody, BRow, BCol, BCardText,
  } from 'bootstrap-vue'
  import VueApexCharts from 'vue-apexcharts'
  import { $themeColors } from '@themeConfig'
  
  //dompurify prevent xss
  import * as DOMPurify from 'dompurify'
 
  export default {
    components: {
      VueApexCharts,
      BCard,
      BCardHeader,
      BCardTitle,
      BDropdown,
      BDropdownItem,
      BCardText,
      BCardBody,
      BRow,
      BCol,
    },
    data() {
      return {
        junochartdatarisk: [0,0,0,0],

        trackerData: {},
        supportTrackerRadialBar: {
        //  series: [0],
          chartOptions: {
            plotOptions: {
              radialBar: {
                size: 150,
                offsetY: 20,
                startAngle: -150,
                endAngle: 150,
                hollow: {
                  size: '65%',
                },
                track: {
                  background: '#fff',
                  strokeWidth: '100%',
                },
                dataLabels: {
                  name: {
                    offsetY: -5,
                    color: '#5e5873',
                    fontSize: '1rem',
                  },
                  value: {
                    offsetY: 15,
                    color: '#5e5873',
                    fontSize: '1.714rem',
                  },
                },
              },
            },
            colors: [$themeColors.danger],
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'dark',
                type: 'horizontal',
                shadeIntensity: 0.5,
                gradientToColors: [$themeColors.primary],
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100],
              },
            },
            stroke: {
              dashArray: 8,
            },
            labels: ['Cyber Risk Score'],
          },
        },
      }
    },

    mounted(){
     
      setTimeout(()=> {
          //timeout for lazy loading and to load after fetching data only

const userapikey =  localStorage.getItem('userapikey')
const scandomain = localStorage.getItem('scandomain')
const scanid = localStorage.getItem('scanid')

let userapikeysanitized = DOMPurify.sanitize(userapikey);
let scandomainsanitized = DOMPurify.sanitize(scandomain);
let scanidsanitized = DOMPurify.sanitize(scanid);



this.$http.get(`${process.env.VUE_APP_SECURITYINFINITYFRONTEND_URL}`+'/api/report_dashboard/fetchreport?reporttype=findingseveritystatscount&userapikey='+userapikeysanitized+'&scandomain='+scandomainsanitized+'&scanid='+scanidsanitized)
    .then((response) => {


      if (response.data.critical == null)
        global.criticalcount = 0
      else 
        global.criticalcount = response.data.critical

      if (response.data.high == null)
        global.highcount = 0
      else 
        global.highcount = response.data.high

      if (response.data.medium == null)
        global.mediumcount = 0
      else 
        global.mediumcount = response.data.medium

      if (response.data.low == null)
        global.lowcount = 0
      else 
        global.lowcount = response.data.low


  const criticalscore=criticalcount
  const highscore=highcount
  const mediumscore=mediumcount
  const lowscore=lowcount
  
  global.scanscore = [(((criticalscore*10+ highscore*9 + mediumscore*6.9 + lowscore*3.9)*10)/10)]
  
  //console.log(global.scanscore)
  


     //sets the chart values on chart with id  junoseveritychart
      //values will be only accessible when variables are declared as global 
     
    
      this.$refs.junoseveritychartrisk.updateSeries	(global.scanscore, true )
      
      //the lines below works tested by juno
      //global.outputformats =  [{ data:[5,5,5,5] }]
      //this.$refs.junoseveritychartaa.updateSeries	(global.outputformats , true )

    })
  }, 2000)
  

}
  }
  </script>
  