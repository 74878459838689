import { $themeColors } from '@themeConfig'

export default {


barChart: {
  //  series: [
  //    {
  //      data: [5,3,1,5],
  //    },
 //   ],
    chartOptions: {
      chart: {
        id: 'junoseveritychart2',
        toolbar: {
          show: false,
        },
      },
      colors: $themeColors.info,
      plotOptions: {
        bar: {
          distributed: true, // this line is mandatory
          horizontal: false,
          barHeight: '30%',
          columnWidth: '25%',
          endingShape: 'rounded',
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        
      },
      colors: [ // this array contains different color code for each data
      "#EA5455", 
       "#2563EB",
       "#00CFE8",      
       "#28C76F"
  

    ],
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: ['critical','high','medium','low'],
      },
      yaxis: {
        // opposite: isRtl,
        labels: {
          show: false
        },
      },
      fill: {
     

      },
    },
  },
}
