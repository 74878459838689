<template>
    <b-card no-body>
      <b-card-header>
        <!-- title and subtitle -->
        <div>
  
          <b-card-title class="font-weight-bolder">
            Severity
          </b-card-title>
        </div>
        <!--/ title and subtitle -->
  
        <!-- datepicker -->
        <div class="d-flex align-items-center">
        
  
        </div>
        <!-- datepicker -->
      </b-card-header>
  
      <b-card-body>
        <vue-apex-charts
          ref="junoseveritychartaa"
          type="bar"
          height="350"
          :options="severityChartData.barChart.chartOptions"
          :series="junochartdataaa"
        />
      </b-card-body>
    </b-card>
  </template>
  
  <script>
  import {
    BCard, BCardHeader, BCardBody, BCardSubTitle, BCardTitle,
  } from 'bootstrap-vue'
  import VueApexCharts from 'vue-apexcharts'
  import severityChartData from '../juno_components/severityChartData'
  
  //dompurify prevent xss
  import * as DOMPurify from 'dompurify'


  export default {
    components: {
      VueApexCharts,
      BCard,
      BCardHeader,
      BCardBody,
      BCardSubTitle,
      BCardTitle,
    },
    data() {
      return {
        junochartdataaa: [{ data:[0,0,0,0] }],
        severityChartData,
       

        }
    },
    mounted(){
    

  const userapikey =  localStorage.getItem('userapikey')
  const scandomain = localStorage.getItem('scandomain')
  const scanid = localStorage.getItem('scanid')

  let userapikeysanitized = DOMPurify.sanitize(userapikey);
  let scandomainsanitized = DOMPurify.sanitize(scandomain);
  let scanidsanitized = DOMPurify.sanitize(scanid);

  setTimeout(()=> {
        //timeout for lazy loading and to load after fetching data only


    this.$http.get(`${process.env.VUE_APP_SECURITYINFINITYFRONTEND_URL}`+'/api/report_dashboard/fetchreport?reporttype=findingseveritystatscount&userapikey='+userapikeysanitized+'&scandomain='+scandomainsanitized+'&scanid='+scanidsanitized)
    .then((response) => {

     
      if (response.data.critical == null)
        global.criticalcount = 0
      else 
        global.criticalcount = response.data.critical

      if (response.data.high == null)
        global.highcount = 0
      else 
        global.highcount = response.data.high

      if (response.data.medium == null)
        global.mediumcount = 0
      else 
        global.mediumcount = response.data.medium

      if (response.data.low == null)
        global.lowcount = 0
      else 
        global.lowcount = response.data.low




     // global.output = [response.data.critical,response.data.high,response.data.medium,response.data.low]
   //   global.output = [1,5,5,5,5]
        global.output = [criticalcount,highcount,mediumcount,lowcount]

        global.outputformat =  [{name: 'junochartdataaa',data:global.output }]

     

      //sets the chart values on chart with id  junoseveritychart
      //values will be only accessible when variables are declared as global 
     

      this.$refs.junoseveritychartaa.updateSeries	(global.outputformat , true )
      
      //the lines below works tested by juno
      //global.outputformats =  [{ data:[5,5,5,5] }]
      //this.$refs.junoseveritychartaa.updateSeries	(global.outputformats , true )

    })
  
  }, 3000)

}

   
  }
  </script>
  <style>
  .apexcharts-gridline {
    stroke-width: 0px;
  }
  </style>
  <style lang="scss">
  @import '@core/scss/vue/libs/chart-apex.scss';
  </style>
  