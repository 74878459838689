<template>

  <div>


  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
        <b-card-title class="mb-1">
         Live Scan
        </b-card-title>
        <b-card-sub-title>Scan Progress Rate</b-card-sub-title>
      <!--/ title and subtitle -->

      <!-- datepicker -->
  
      <!-- datepicker -->
    </b-card-header>

    <b-card-body>
      <progress-chart />

    </b-card-body>

  </b-card>


      
    <b-row class="match-height">
      <b-col         lg="6"
>
        <b-card>

        <risk-score />
      </b-card>

      </b-col>
      <b-col lg="6">
        <b-card>

        <severity-chart />
      </b-card>
      </b-col>
    </b-row>

    <b-row class="match-height">

      <b-col>
        <b-card>
        <scan-statistics />

        

      </b-card>

      </b-col>
    </b-row>


    <b-row class="match-height">


<b-col>
  <b-card>
  <vulnerability-type-donut-chart />

</b-card>

</b-col>
</b-row>


    

</div>

</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle,BRow, BCol 
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import RiskScore from '././juno_components/RiskScore.vue'
import SeverityChart from '././juno_screens/SeverityChart.vue'
import ScanStatistics from '././juno_screens/ScanStatistics.vue'
import VulnerabilityTypeDonutChart from '././juno_screens/VulnerabilityTypeDonutChart.vue'
import ProgressChart from './juno_components/ScanProgressChartData.vue'

  


export default {
  components: {
    BRow,
    BCol,
    BCard,
    VueApexCharts,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    ProgressChart,
    RiskScore,
    SeverityChart,
    ScanStatistics,
    VulnerabilityTypeDonutChart
},
  data() {
    return {
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';

//hides gridelines by the chart --juno fix
.apexcharts-gridline {
    stroke-width: 0px;
}
</style>
