import { render, staticRenderFns } from "./SeverityChart.vue?vue&type=template&id=6aff24bc"
import script from "./SeverityChart.vue?vue&type=script&lang=js"
export * from "./SeverityChart.vue?vue&type=script&lang=js"
import style0 from "./SeverityChart.vue?vue&type=style&index=0&id=6aff24bc&prod&lang=css"
import style1 from "./SeverityChart.vue?vue&type=style&index=1&id=6aff24bc&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports